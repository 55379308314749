import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import stepsIllustrationSrc from '../../images/steps-screenshot.png';
import svgDotPattern from '../../images/dot-pattern.svg';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = (css) => (
    <img src={svgDotPattern} className={css.className} style={css._css} tw="w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10" alt="" />
);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
    imageSrc = stepsIllustrationSrc,
    imageRounded = true,
    imageBorder = false,
    imageShadow = false,
    imageDecoratorBlob = true,
    textOnLeft = false,
    decoratorBlobCss = {
        opacity: '0.15',
        '--transform-translate-x': '-50%',
        left: '50%',
        '@media (minWidth: 1280px)': { height: '10rem', width: '10rem' },
        fill: '#5E6EAF !important',
    },
}) => {
    const { t } = useTranslation();

    const steps = [
        {
            heading: t('steps.cards.1.heading'),
            description: (
                <>
                    {t('steps.cards.1.description.a')}
                    <br />
                    <a href="https://auth0.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>{t('steps.cards.1.description.b')}</a>
                </>
            ),
        },
        {
            heading: t('steps.cards.2.heading'),
            description: t('steps.cards.2.description'),
        },
        {
            heading: t('steps.cards.3.heading'),
            description: t('steps.cards.3.description'),
        },
    ];

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image src={imageSrc} alt="Auth0 ensures security" imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
                    {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{t('steps.subheading')}</Subheading>
                        <Heading>
                            {t('steps.heading.a')}
                            <span tw="text-primary-500">{t('steps.heading.b')}</span>
                        </Heading>
                        <Steps>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                    <StepText>
                                        <StepHeading>{step.heading}</StepHeading>
                                        <StepDescription>{step.description}</StepDescription>
                                    </StepText>
                                </Step>
                            ))}
                        </Steps>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
