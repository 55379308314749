import React from 'react';
import './style.css';
import 'tailwindcss/dist/base.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Hero from '../components/Content/Hero';
import Banner from '../components/Content/Banner';
import Features from '../components/Content/Features';
import Providers from '../components/Content/Providers';
import DownloadApp from '../components/Content/DownloadApp';
import Values from '../components/Content/Values';
import Steps from '../components/Content/Steps';
import Footer from '../components/Layout/Footer';
import AnimationRevealPage from '../helpers/AnimationRevealPage';
import Problems from '../components/Content/Problems';
import Helmet from '../components/Layout/Helmet';

export default function IndexPage() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet title={t('helmet.home.title')} desc={t('helmet.home.description')} meta={t('helmet.home.meta')}>
                <script src="https://cdn.jsdelivr.net/npm/vivid-icons@1.0.10" type="text/javascript" />
            </Helmet>
            <AnimationRevealPage disabled>
                <Hero />
                <Features />
                <Problems />
                <Providers />
                <Steps />
                <DownloadApp />
                <Values />
                <Banner />
                <Footer />
            </AnimationRevealPage>
        </>
    );
}
