import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import checkboxIcon from '../../images/icons/check-circle.svg';
import { SectionDescription } from '../Misc/Typography';
import { PrimaryButton as PrimaryButtonBase } from '../Misc/Buttons';
import { Container, ContentWithVerticalPadding } from '../Misc/Layouts';
import svgDecoratorBlob1 from '../../images/dot-pattern.svg';
import unsplashHero from '../../images/unsplash-hero.jpg';
import Header from '../Layout/Header';

const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = styled.h1`
  ${tw`text-4xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-black leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const Description = tw(SectionDescription)`mt-4 text-center lg:text-left text-gray-700 max-w-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`relative mt-8 rounded-full inline-block tracking-wide text-center py-5 z-10`;
const ButtonContainer = tw.div`text-center lg:text-left`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center`;
const CheckboxIcon = () => (
    <img src={checkboxIcon} fill="#4a5568" alt="" />
);

const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = () => (
    <img src={svgDecoratorBlob1} tw="pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current" alt="" />
);
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const Quote = tw.blockquote``;

const Wrapper = styled.div`
  position: relative;
  background: linear-gradient(0deg, #c0c6ff 0%, #cdbbfd 100%);
  padding: 2rem 2rem 6rem 2rem;
  
  &&::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url("https://filtermaker.fr/wp-content/themes/appilo/img/app-showcase/shape/bl-shape.png");
  }
`;

const Wave = styled.div`
  position: absolute;
  width: 200%;
  height: 100%;
  background-image: url("https://filtermaker.fr/wp-content/themes/appilo/img/app-showcase/shape/b-shapeup.png");
  bottom: 0;
  left: 0;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  background-size: 50% 80px;
`;

const MovingWave = styled.div`
  animation: move_wave 8s linear infinite;
  position: absolute;
  background-image: url("https://filtermaker.fr/wp-content/themes/appilo/img/app-showcase/shape/b-shapemiddle.png");
  bottom: 0;
  opacity: .5;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  background-size: 50% 120px;
`;

const MovingWave2 = styled.div`
  animation: move_wave 15s linear infinite;
  position: absolute;
  background-image: url("https://filtermaker.fr/wp-content/uploads/2020/10/b-shapemiddle-2-1.png");
  bottom: 0;
  opacity: .5;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  background-size: 50% 120px;
`;

export default ({
    imageSrc = unsplashHero,
    imageDecoratorBlob = true,
    primaryButtonUrl = 'https://center.lunaar.app',
}) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Header />
            <Container>
                <ContentWithVerticalPadding>
                    <Row>
                        <TextColumn>
                            <Heading>
                                {t('hero.heading.a')}
                                <br />
                                <div style={{ position: 'relative', zIndex: '1' }}>
                                    <span className="slanted">{t('hero.heading.b')}</span>
                                </div>
                            </Heading>
                            <Description>{t('hero.description')}</Description>
                            <ButtonContainer>
                                <PrimaryButton as="a" href={primaryButtonUrl}>
                                    {t('hero.button')}
                                </PrimaryButton>
                            </ButtonContainer>
                            <FeatureList>
                                {[t('hero.features.1'), t('hero.features.2'), t('hero.features.3')].map((feature, index) => (
                                    <Feature key={index}>
                                        <FeatureIcon />
                                        <FeatureText>{feature}</FeatureText>
                                    </Feature>
                                ))}
                            </FeatureList>
                        </TextColumn>
                        <ImageColumn>
                            <ImageContainer>
                                <Image src={imageSrc} alt={t('hero.testimonial.title')} />
                                {imageDecoratorBlob && <ImageDecoratorBlob />}
                                <Testimonial>
                                    <Quote><strong style={{ color: '#fff' }}>{t('hero.testimonial.title')}</strong></Quote>
                                    <Quote>{t('hero.testimonial.quote')}</Quote>
                                </Testimonial>
                            </ImageContainer>
                            <Offsetbackground />
                        </ImageColumn>
                    </Row>
                </ContentWithVerticalPadding>
            </Container>
            <Wave />
        </Wrapper>
    );
};
