import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
// eslint-disable-next-line
import { css } from "styled-components/macro";
import clockIcon from 'feather-icons/dist/icons/clock.svg';
import shareIcon from 'feather-icons/dist/icons/share-2.svg';
import zapIcon from 'feather-icons/dist/icons/award.svg';
import arrowUpIcon from 'feather-icons/dist/icons/arrow-up-circle.svg';
import bellIcon from 'feather-icons/dist/icons/target.svg';
import checkIcon from 'feather-icons/dist/icons/check-circle.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionDescription } from '../Misc/Typography';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import logo from '../../images/logo_white.svg';
import svgDecoratorBlob1 from '../../images/svg-decorator-blob-9.svg';

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`w-full mb-4 text-center text-gray-100`;
const Heading = tw(SectionHeading)`w-auto`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

const FeatureIconContainer = styled.span`
  ${tw`inline-block border border-primary-500 text-primary-500 text-center rounded p-2 flex-shrink-0`}
  ${(props) => [
        props.iconRoundedFull && tw`rounded-full`,
        props.iconFilled && tw`border-0 bg-primary-500 text-gray-100`,
    ]}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;

const DecoratorBlob1 = () => (
    <img src={svgDecoratorBlob1} tw="absolute top-0 left-0 w-80 h-80 transform -translate-x-16 -translate-y-32 text-primary-500 opacity-5" alt="" />
);

const DecoratorBlob2 = () => (
    <img src={svgDecoratorBlob1} tw="absolute bottom-0 right-0 w-80 h-80 transform  -translate-x-20 translate-y-64 text-primary-500 opacity-5" alt="" />
);

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();
    const cards = [
        {
            Icon: () => (<img src={clockIcon} alt="" />),
            title: t('problems.cards.1.heading'),
            description: t('problems.cards.1.description'),
            iconContainerCss: tw`bg-teal-300 text-teal-800`,
        },
        {
            Icon: () => (<img src={arrowUpIcon} alt="" />),
            title: t('problems.cards.2.heading'),
            description: t('problems.cards.2.description'),
            iconContainerCss: tw`bg-pink-300 text-pink-800`,
        },
        {
            Icon: () => (<img src={bellIcon} alt="" />),
            title: t('problems.cards.3.heading'),
            description: t('problems.cards.3.description'),
            iconContainerCss: tw`bg-purple-300 text-purple-800`,
        },
        {
            Icon: () => (<img src={shareIcon} alt="" />),
            title: t('problems.cards.4.heading'),
            description: t('problems.cards.4.description'),
            iconContainerCss: tw`bg-indigo-300 text-indigo-800`,
        },
        {
            Icon: () => (<img src={zapIcon} alt="" />),
            title: t('problems.cards.5.heading'),
            description: t('problems.cards.5.description'),
            iconContainerCss: tw`bg-orange-400 text-orange-800`,
        },
        {
            Icon: () => (<img src={checkIcon} alt="" />),
            title: t('problems.cards.6.heading'),
            description: t('problems.cards.6.description'),
            iconContainerCss: tw`bg-yellow-300 text-yellow-800`,
        },
    ];

    return (
        <Wrapper>
            <Container>
                <ThreeColumnContainer>
                    <Subheading>{t('problems.subheading')}</Subheading>
                    <Heading>
                        <span style={{ float: 'left' }}>{t('problems.heading')}</span>
                        <br className="problems-br" />
                        <img
                            className="problems-logo"
                            src={logo}
                            alt="logo"
                        />
                        <span className="problems-span">
                            LUNAAR
                        </span>
                    </Heading>
                    <Description>{t('problems.description')}</Description>
                    <VerticalSpacer />
                    {cards.map((card, i) => (
                        <Column key={i}>
                            <Card>
                                <FeatureIconContainer
                                    iconFilled
                                    css={card.iconContainerCss || null}
                                >
                                    {card.Icon()}
                                </FeatureIconContainer>
                                <span className="textContainer">
                                    <span className="title">{card.title || 'Fully Secure'}</span>
                                    <p className="description">
                                        {card.description || 'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                                    </p>
                                </span>
                            </Card>
                        </Column>
                    ))}
                </ThreeColumnContainer>
                <DecoratorBlobContainer>
                    <DecoratorBlob1 />
                    <DecoratorBlob2 />
                </DecoratorBlobContainer>
            </Container>
        </Wrapper>
    );
};
