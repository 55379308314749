import React from 'react';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { ContentWithPaddingXl, Container } from '../Misc/Layouts';
import svgDecoratorBlob1 from '../../images/svg-decorator-blob-9.svg';

const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 rounded-lg relative`;
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const LeftColumnContainer = tw.div`lg:w-3/4`;
const RightColumnContainer = tw.div`lg:w-1/4 max-w-lg`;
const TextContainer = tw(LeftColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6`text-primary-500 opacity-75`;
const Heading = tw.h5`text-primary-500`;

const LinksContainer = tw(RightColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`;

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-hover`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;

const DecoratorBlob1 = () => (
    <img src={svgDecoratorBlob1} tw="absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-primary-500 opacity-5" alt="" />
);

const DecoratorBlob2 = () => (
    <img src={svgDecoratorBlob1} tw="absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-primary-500 opacity-5" alt="" />
);

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Container>
                <ContentWithPaddingXl>
                    <PrimaryBackgroundContainer style={{ backgroundColor: '#cdbbfd' }}>
                        <Row>
                            <TextContainer>
                                <Subheading>{t('banner.subheading')}</Subheading>
                                <Heading style={{ color: '#5E6EAF' }}>{t('banner.heading')}</Heading>
                            </TextContainer>
                            <LinksContainer>
                                <PrimaryLink href="https://center.lunaar.app">{t('banner.button')}</PrimaryLink>
                            </LinksContainer>
                        </Row>
                        <DecoratorBlobContainer>
                            <DecoratorBlob1 />
                            <DecoratorBlob2 />
                        </DecoratorBlobContainer>
                    </PrimaryBackgroundContainer>
                </ContentWithPaddingXl>
            </Container>
        </Wrapper>
    );
};
