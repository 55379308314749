import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
// eslint-disable-next-line
import { css } from "styled-components/macro";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SectionHeading, Subheading as SubheadingBase } from '../Misc/Headings';
import { SectionDescription } from '../Misc/Typography';

import defaultCardImage from '../../images/shield-icon.svg';

import svgDecoratorBlob3 from '../../images/svg-decorator-blob-3.svg';
import featuresScreenshotSrc from '../../images/features-screenshot.svg';

import ReportsIconImage from '../../images/reports.svg';
import ListIconImage from '../../images/resources.svg';
import DashboardIconImage from '../../images/dashboard.svg';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const ColumnContainer = tw.div`max-w-6xl`;
const ImageContainer = tw(ColumnContainer)`mt-16 lg:mt-0`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full flex-shrink-0`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = () => (
    <img src={svgDecoratorBlob3} tw="pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48" alt="" />
);

const Wrapper = styled.div`
  position: relative;
  padding: 0 2rem 0 2rem;
`;

export default () => {
    const { t } = useTranslation();

    const cards = [
        {
            imageSrc: ReportsIconImage,
            title: t('features.cards.1.title'),
            description: t('features.cards.1.description'),
        },
        {
            imageSrc: ListIconImage,
            title: t('features.cards.2.title'),
            description: t('features.cards.2.description'),
        },
        {
            imageSrc: DashboardIconImage,
            title: t('features.cards.3.title'),
            description: t('features.cards.3.description'),
        },
        {
            imageSrc: ReportsIconImage,
            title: t('features.cards.4.title'),
            description: t('features.cards.4.description'),
        },
        {
            imageSrc: ListIconImage,
            title: t('features.cards.5.title'),
            description: t('features.cards.5.description'),
        },
        {
            imageSrc: DashboardIconImage,
            title: t('features.cards.6.title'),
            description: t('features.cards.6.description'),
        },
    ];

    return (
        <Wrapper>
            <Container>
                <ThreeColumnContainer>
                    <Subheading>{t('features.subheading')}</Subheading>
                    <Heading>{t('features.heading')}</Heading>
                    <Description>{t('features.description')}</Description>
                    <ImageContainer>
                        <img src={featuresScreenshotSrc} tw="lg:mt-10" style={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)' }} alt="" />
                    </ImageContainer>
                    <VerticalSpacer />
                    {cards.map((card, i) => (
                        <Column key={i}>
                            <Card>
                                <span className="imageContainer">
                                    <img src={card.imageSrc || defaultCardImage} alt="" />
                                </span>
                                <span className="textContainer">
                                    <span className="title">{card.title || 'Fully Secure'}</span>
                                    <p className="description">
                                        {card.description || 'Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud.'}
                                    </p>
                                </span>
                            </Card>
                        </Column>
                    ))}
                </ThreeColumnContainer>
                <DecoratorBlob />
            </Container>
        </Wrapper>
    );
};
